import React, { useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { moneyFormat } from '../../utils/money';

const CustomTable = ({ headers, data }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const sortedData = React.useMemo(() => {
    const sortableItems = [...data];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const stripeColor = useColorModeValue('gray.50', 'gray.900');
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box width='full' overflowX='auto'>
      <Table variant='simple' colorScheme='blue'>
        <Thead bg='blue.800'>
          <Tr>
            {headers.map(header => (
              !isMobile || header.isImportant ? (
                <Th
                  key={header.field}
                  color='white'
                  textAlign={header.textAlign || 'left'}
                  cursor={header.sortable ? 'pointer' : 'default'}
                  onClick={() => header.sortable && requestSort(header.field)}
                >
                  {header.label}
                  {header.sortable && (sortConfig.key === header.field ? (sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />) : <FontAwesomeIcon icon={faSort} />)}
                </Th>
              ) : null
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {sortedData.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tr key={index} bg={index % 2 === 0 ? 'white' : stripeColor} color='blue.800'>
              {headers.map(header => (
                !isMobile || header.isImportant ? (
                  <Td
                    textAlign={header.textAlign || 'left'}
                    color={header.color || 'inherit'}
                    fontWeight={header.isBold ? 'bold' : 'normal'}
                  >
                    {header.isMoney ? moneyFormat(item[header.field]) : item[header.field]}
                  </Td>
                ) : null
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default CustomTable;
