import React, { useEffect } from 'react';
import { Box, VStack, Text, Spinner, Accordion, useToast } from '@chakra-ui/react';
import { faReceipt, faGears, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { useEstablishmentsManagement } from 'hooks';
import { useGlobalContext } from 'context/globalContext';
import SidebarLink from './SidebarLink';
import EstablishmentsMenu from './EstablishmentsMenu';

const SidebarContent = ({ onClose, isOpen }) => {
  const { dispatch } = useGlobalContext();
  const toast = useToast();
  const { getEstablishmentsManagement, isLoading, establishments } = useEstablishmentsManagement();

  const handleSelectEstablishment = establishment => {
    dispatch({
      type: 'SET_SELECTED_ESTABLISHMENT',
      payload: establishment,
    });
    toast({
      title: 'Establecimiento Seleccionado',
      description: `Has seleccionado: ${establishment.name}`,
      status: 'info',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
  };

  useEffect(() => {
    getEstablishmentsManagement();
  }, []);

  useEffect(() => {
    if (establishments.length > 0) {
      handleSelectEstablishment(establishments[0]);
    }
  }, [establishments]);

  return (
    <Box
      bg='blue.800'
      w={{ base: 'full', lg: 250 }}
      p='5'
      h='full'
      color='white'
      pos='fixed'
      left='0'
      top='0'
      zIndex='1'
      display={isOpen ? 'block' : 'none'}
    >
      <VStack spacing={5} align='stretch'>
        <Text fontSize='2xl' fontWeight='bold' mb={5}>Menú</Text>
        {!isLoading ? (
          <>
            <Accordion allowToggle>
              <EstablishmentsMenu establishments={establishments} handleSelectEstablishment={handleSelectEstablishment} />
            </Accordion>
            <SidebarLink to='/admin/tickets' icon={faReceipt} label='Tickets' onClose={onClose} />
            <SidebarLink to='/admin/facturacion' icon={faFileInvoice} label='Facturación' onClose={onClose} />
            <SidebarLink to='/admin/configuracion' icon={faGears} label='Configuracion' onClose={onClose} />
          </>
        ) : <Spinner color='white' />}
      </VStack>
    </Box>
  );
};

export default SidebarContent;
