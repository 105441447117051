import { isEmptyObject } from 'utils/funGeneral';

const useGetPropertiesForm = () => {

  const setClearTouchableInitial = (field, value, nameRef) => {
    setTimeout(() => {
      nameRef?.current?.setFieldTouched(field, false);
    }, 600);
  };

  const getTouchedField = (field, nameRef) => {
    setTimeout(() => {
      nameRef?.current?.setFieldTouched(field, true);
    }, 1000);
  };

  const setFieldForm = (field, value, nameRef) => {
    const valueNoValid = value === 'Filled';
    if (value !== null && value !== undefined && !valueNoValid) {
      if (typeof value === 'string' && value.trim() !== '') { //validar si un valor es string limpiarlo
        nameRef?.current?.setFieldValue(field.trim(), value);
      } else {
        nameRef?.current?.setFieldValue(field, value);
      }
      getTouchedField(field, nameRef);
    }
  };

  const getValuesInitial = (object, nameRef, fun) => {
    if (!isEmptyObject(object)) { //se valida la nullabilidad el objeto
      // eslint-disable-next-line no-restricted-syntax
      for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          if (fun) { //En caso de no venrir una funcion en eespecifico se ejecuta la funcion general
            fun(key, object[key], nameRef);
          } else {
            setFieldForm(key, object[key], nameRef);
          }
        }
      }
    }
  };

  const resetEstablishment = nameRef => {
    //SE RESETEA EL FORM PARA OBTENER LOS NUEVOS VALORES
    nameRef?.current?.resetForm();
  };

  return { getValuesInitial, getTouchedField, setFieldForm, resetEstablishment, setClearTouchableInitial };
};

export default useGetPropertiesForm;
