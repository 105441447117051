import React, { useState, useEffect } from 'react';
import { Box, Spinner, Select, Button, Text, VStack, Flex } from '@chakra-ui/react';
import { useEstablishments } from 'hooks';
import EstablishmentForm from './EstablishmentForm';
import BillingForm from './BillingForm';
import TicketInfoCard from './TicketInfoCard';
import InvoiceActions from './InvoiceActions';
import Footer from './Footer';

const HomePage = () => {
  const { establishments, fetchEstablishments, isLoading, error } = useEstablishments();
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [invoiceResend, setInvoiceResend] = useState(null);

  useEffect(() => {
    fetchEstablishments();
  }, []);

  const reset = () => {
    setTicket(null);
    setSelectedEstablishment(null);
    setInvoice(null);
    setInvoiceResend(null);
  };

  const handleTicket = t => (t.invoice === null ? setTicket(t) : setInvoiceResend(t.invoice));

  const handleInvoice = t => setInvoice(t);

  const handleEstablishmentChange = e => {
    // eslint-disable-next-line radix
    const establishment = establishments.find(est => est.id === parseInt(e.target.value));
    setSelectedEstablishment(establishment);
    setTicket(null);
  };

  return (
    <Flex
      direction='column'
      align='center'
      w='full'
      h='100vh'
      bgGradient='linear(to-r, brand.500, brand.900)'
      color='white'

    >
      <Box w='full' pt={4} pb={8} textAlign='center'>
        <Text fontSize='2xl' fontWeight='bold'>
          Bienvenido al sistema de facturación
        </Text>
        <Text>
          Desde aquí podrás solicitar nuevas facturas fácilmente.
        </Text>
      </Box>

      <Flex grow={1} align='center' justify='center' w='full'>
        <VStack spacing={4} align='stretch' w='full' maxW='500px'>
          {
            invoice || invoiceResend ? (
              <InvoiceActions invoice={invoice} invoiceResend={invoiceResend} reset={reset} />
            ) : (
              isLoading ? (
                <>
                  <Text>Cargando establecimientos...</Text>
                  <Spinner color='white' />
                </>
              ) : error ? (
                <>
                  <Text>{error}</Text>
                  <Button colorScheme='teal' onClick={fetchEstablishments}>Reintentar</Button>
                </>
              ) : establishments.length > 0 ? (
                <>
                  <Select
                    onChange={handleEstablishmentChange}
                    placeholder='Selecciona un establecimiento'
                    size='lg'
                    value={selectedEstablishment ? selectedEstablishment.id : ''}
                  >
                    {establishments.map(establishment => (
                      <option key={establishment.id} value={establishment.id} style={{ color: 'black' }}>
                        {establishment.name}
                      </option>
                    ))}
                  </Select>

                  {selectedEstablishment && (
                    ticket ? (
                      <>
                        <TicketInfoCard
                          selectedEstablishment={selectedEstablishment}
                          ticket={ticket}
                        />
                        <BillingForm
                          selectedEstablishment={selectedEstablishment}
                          ticket={ticket}
                          handleInvoice={handleInvoice}
                          reset={reset}
                        />
                      </>
                    ) : (
                      <EstablishmentForm
                        selectedEstablishment={selectedEstablishment}
                        handleTicket={handleTicket}
                        reset={reset}
                      />
                    )
                  )}
                </>
              ) : (
                <Text>No hay establecimientos disponibles.</Text>
              )
            )
          }
        </VStack>
      </Flex>

      <Footer />
    </Flex>
  );
};

export default HomePage;
