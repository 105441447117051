import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
  Grid,
  Text,
  FormControl,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faFilePdf, faPaperPlane, faBan } from '@fortawesome/free-solid-svg-icons';
import useInvoice from 'hooks/useInvoice';
import { useParams } from 'react-router-dom';
import InvoiceDisplay from './InvoiceDisplay';

const InvoiceDetails = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isOpen: isOpenResend, onOpen: onOpenResend, onClose: onCloseResend } = useDisclosure();
  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure();
  const { id } = useParams();
  const { retrieveInvoice, invoice } = useInvoice();

  useEffect(() => {
    retrieveInvoice(id);
  }, []);

  const handleResend = () => {
    console.log('Reenviando factura a:', email);
    onCloseResend();
  };

  const handleCancel = () => {
    console.log('Cancelando factura con contraseña:', password);
    onCloseCancel();
  };

  return (
    <Box>
      {
        invoice ? (
          <InvoiceDisplay invoice={invoice} />
        ) : null
      }
      <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }} gap={4} p={4}>
        <Button leftIcon={<FontAwesomeIcon icon={faFileCode} />} colorScheme='blue' onClick={() => console.log('Ver XML')}>
          Ver XML
        </Button>
        <Button leftIcon={<FontAwesomeIcon icon={faFilePdf} />} colorScheme='red' onClick={() => console.log('Ver PDF')}>
          Ver PDF
        </Button>
        <Button leftIcon={<FontAwesomeIcon icon={faPaperPlane} />} colorScheme='green' onClick={onOpenResend}>
          Reenviar
        </Button>
        <Button leftIcon={<FontAwesomeIcon icon={faBan} />} colorScheme='red' onClick={onOpenCancel}>
          Cancelar
        </Button>
      </Grid>

      {/* Modal for resending invoice */}
      <Modal isOpen={isOpenResend} onClose={onCloseResend}>
        <ModalOverlay />
        <ModalContent backgroundColor='white'>
          <ModalHeader color='blue.800'>Reenviar Factura</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <Input placeholder='Ingrese correo electrónico' value={email} onChange={e => setEmail(e.target.value)} color='blue.800' />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' onClick={handleResend}>Enviar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for cancelling invoice */}
      <Modal isOpen={isOpenCancel} onClose={onCloseCancel}>
        <ModalOverlay />
        <ModalContent backgroundColor='white'>
          <ModalHeader color='blue.800'>Confirmar Cancelación</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color='blue.800' mb={4}>Ingrese su contraseña para confirmar la cancelación de la factura.</Text>
            <Input type='password' placeholder='Contraseña' value={password} onChange={e => setPassword(e.target.value)} color='blue.800' />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='red' onClick={handleCancel}>Confirmar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InvoiceDetails;
