export const getFormData = async values => {
  console.log(values);
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      if (typeof values[key] === 'string' && values[key].trim() !== '') {
        // Validar si un valor es string y limpiarlo
        console.log('es un string limpiarlo');
        formData.append(key, values[key].trim());
      } else {
        formData.append(key, values[key]);
      }
    }
  }

  return formData;
};

export const isEmptyObject = obj => {
  return Object.keys(obj).length === 0;
};
