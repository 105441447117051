import React from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, useToast, FormErrorMessage, Text } from '@chakra-ui/react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useSessionUser } from 'hooks';
import { useGlobalContext } from 'context/globalContext';

const LoginForm = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch } = useGlobalContext();
  const { login, error, setSession } = useSessionUser();
  const from = location.state?.from?.pathname || '/admin/facturacion';
  const toast = useToast();

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .email('Introduce un correo electrónico válido')
      .required('El correo electrónico es obligatorio'),
    password: Yup.string()
      .required('La contraseña es obligatoria')
      .min(8, 'La contraseña debe tener al menos 8 caracteres'),
  });

  const handleLogin = async (values, actions) => {
    await login(values, async data => {
      await setSession(data);
      dispatch({
        type: 'LOGIN',
        payload: data,
      });
      toast({
        title: 'Ingreso exitoso',
        description: 'Has iniciado sesión correctamente.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      actions.setSubmitting(false);
      navigate(from, { replace: true });
    });
  };

  return (
    <Box width='full' maxW='md' mx='auto' mt={10}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <VStack spacing={4}>
              <FormControl isInvalid={touched.username && errors.username}>
                <FormLabel>Correo Electrónico</FormLabel>
                <Field as={Input} id='username' name='username' placeholder='Ingresa tu correo electrónico' />
                <ErrorMessage component={FormErrorMessage} name='username' />
              </FormControl>
              <FormControl isInvalid={touched.password && errors.password}>
                <FormLabel>Contraseña</FormLabel>
                <Field as={Input} id='password' name='password' type='password' placeholder='Ingresa tu contraseña' />
                <ErrorMessage component={FormErrorMessage} name='password' />
              </FormControl>
              {error !== null && (
                <Text>{error}</Text>
              )}
              <Button type='submit' colorScheme='teal' size='lg' width='full' isLoading={isSubmitting}>
                Iniciar Sesión
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default LoginForm;
