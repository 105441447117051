import React from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  VStack,
  Heading,
} from '@chakra-ui/react';

const InvoiceDisplay = ({ invoice }) => {
  return (
    <Box p={5}>
      <Accordion allowMultiple defaultIndex={[0]}>
        <AccordionItem>
          <h2>
            <AccordionButton bg='blue.800' _hover={{ bg: 'blue.700' }} color='white'>
              <Box flex='1' textAlign='left'>
                <Heading fontSize='xl'>
                  Información General del Comprobante
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack alignItems='start' spacing={3}>
              <Text color='blue.800'>
                <strong>UUID:</strong>
                {' '}
                {invoice.UUID}
              </Text>
              <Text color='blue.800'>
                <strong>Folio:</strong>
                {' '}
                {invoice.Comprobante.Folio}
              </Text>
              <Text color='blue.800'>
                <strong>Fecha de Facturación:</strong>
                {' '}
                {invoice.Comprobante.Fecha}
              </Text>
              <Text color='blue.800'>
                <strong>Forma de Pago:</strong>
                {' '}
                {invoice.Comprobante.FormaPago}
              </Text>
              <Text color='blue.800'>
                <strong>Condiciones de Pago:</strong>
                {' '}
                {invoice.Comprobante.CondicionesDePago}
              </Text>
              <Text color='blue.800'>
                <strong>SubTotal:</strong>
                {' '}
                {invoice.Comprobante.SubTotal}
              </Text>
              <Text color='blue.800'>
                <strong>Total:</strong>
                {' '}
                {invoice.Comprobante.Total}
              </Text>
              <Text color='blue.800'>
                <strong>Método de Pago:</strong>
                {' '}
                {invoice.Comprobante.MetodoPago}
              </Text>
            </VStack>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton bg='blue.800' _hover={{ bg: 'blue.700' }} color='white'>
              <Box flex='1' textAlign='left'>
                <Heading fontSize='xl'>
                  Datos del Emisor
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack alignItems='start' spacing={3}>
              <Text color='blue.800'>
                <strong>RFC:</strong>
                {' '}
                {invoice.Emisor.Rfc}
              </Text>
              <Text color='blue.800'>
                <strong>Nombre:</strong>
                {' '}
                {invoice.Emisor.Nombre}
              </Text>
              <Text color='blue.800'>
                <strong>Régimen Fiscal:</strong>
                {' '}
                {invoice.Emisor.RegimenFiscal}
              </Text>
            </VStack>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton bg='blue.800' _hover={{ bg: 'blue.700' }} color='white'>
              <Box flex='1' textAlign='left'>
                <Heading fontSize='xl'>
                  Datos del Receptor
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack alignItems='start' spacing={3}>
              <Text color='blue.800'>
                <strong>RFC:</strong>
                {' '}
                {invoice.Receptor.Rfc}
              </Text>
              <Text color='blue.800'>
                <strong>Nombre:</strong>
                {' '}
                {invoice.Receptor.Nombre}
              </Text>
              <Text color='blue.800'>
                <strong>Uso CFDI:</strong>
                {' '}
                {invoice.Receptor.UsoCFDI}
              </Text>
              <Text color='blue.800'>
                <strong>Régimen Fiscal del Receptor:</strong>
                {' '}
                {invoice.Receptor.RegimenFiscalReceptor}
              </Text>
              <Text color='blue.800'>
                <strong>Domicilio Fiscal del Receptor:</strong>
                {' '}
                {invoice.Receptor.DomicilioFiscalReceptor}
              </Text>
            </VStack>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton bg='blue.800' _hover={{ bg: 'blue.700' }} color='white'>
              <Box flex='1' textAlign='left'>
                <Heading fontSize='xl'>
                  Detalles de Conceptos
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <VStack alignItems='start' spacing={4}>
              {invoice.Conceptos.map((concepto, index) => (
                <VStack alignItems='start' spacing={3}>
                  <Text color='blue.800'>
                    <strong>Descripción:</strong>
                    {' '}
                    {concepto.Descripcion}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Cantidad:</strong>
                    {' '}
                    {concepto.Cantidad}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Valor Unitario:</strong>
                    {' '}
                    {concepto.ValorUnitario}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Importe:</strong>
                    {' '}
                    {concepto.Importe}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Objeto de Impuesto:</strong>
                    {' '}
                    {concepto.ObjetoImp}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Clave ProdServ:</strong>
                    {' '}
                    {concepto.ClaveProdServ}
                  </Text>
                  <Text color='blue.800'>
                    <strong>No. Identificación:</strong>
                    {' '}
                    {concepto.NoIdentificacion}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Clave Unidad:</strong>
                    {' '}
                    {concepto.ClaveUnidad}
                  </Text>
                  <Text color='blue.800'>
                    <strong>Unidad:</strong>
                    {' '}
                    {concepto.Unidad}
                  </Text>

                  {concepto.Impuestos.map((impuesto, impIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={impIndex} w='full'>
                      <Text color='blue.800'>
                        <strong>Base Impuesto:</strong>
                        {' '}
                        {impuesto.Base}
                      </Text>
                      <Text color='blue.800'>
                        <strong>Impuesto:</strong>
                        {' '}
                        {impuesto.Impuesto}
                      </Text>
                      <Text color='blue.800'>
                        <strong>Tipo de Factor:</strong>
                        {' '}
                        {impuesto.TipoFactor}
                      </Text>
                      <Text color='blue.800'>
                        <strong>Tasa o Cuota:</strong>
                        {' '}
                        {impuesto.TasaOCuota}
                      </Text>
                      <Text color='blue.800'>
                        <strong>Importe de Impuesto:</strong>
                        {' '}
                        {impuesto.Importe}
                      </Text>
                    </Box>
                  ))}
                </VStack>
              ))}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default InvoiceDisplay;
