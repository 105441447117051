import { useState } from 'react';
import useApi from './useApi';

const useEstablishments = () => {
  const [establishments, setEstablishments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { sendRequest } = useApi();

  const fetchEstablishments = () => {
    setIsLoading(true);
    sendRequest({
      method: 'get',
      url: '/establishments/',
      onSuccess: data => {
        setEstablishments(data);
        setError(null);
      },
      onError: () => {
        setError('No se pudieron cargar los establecimientos');
      },
      onFinish: () => {
        setIsLoading(false);
      },
      closeOnError: false,
    });
  };

  return { establishments, isLoading, error, fetchEstablishments };
};

export default useEstablishments;
