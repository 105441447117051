import { useState } from 'react';
import useApi from 'hooks/useApi';

const useInvoice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [establishmentInvoices, setEstablishmentInvoices] = useState([]);
  const [invoice, setInvoice] = useState(null);

  const { sendRequest } = useApi();

  const getEstablishmentInvoice = async (id, onSuccess) => {
    setIsLoading(true);
    setError(null);
    await sendRequest({
      method: 'get',
      url: `/management/establishments/${id}/invoices`,
      onSuccess: response => {
        if (onSuccess) {
          onSuccess(response);
        }
        setEstablishmentInvoices(response);
        setIsLoading(false);
      },
      onError: error => {
        setError(error?.data?.message || 'Error al obtener las facturas de los establecimientos');
      },
      onFinish: () => setIsLoading(false),
    });
  };

  const retrieveInvoice = idInvoice => {
    setIsLoading(true);
    setError(null);
    sendRequest({
      method: 'get',
      url: `/management/period/read_xml/${idInvoice}`,
      onSuccess: response => setInvoice(response),
      onError: error => setError(error.response.data),
      onFinish: () => setIsLoading(false),
    });
  };

  return {
    isLoading,
    error,
    getEstablishmentInvoice,
    retrieveInvoice,
    invoice,
    establishmentInvoices,
  };

};

export default useInvoice;
