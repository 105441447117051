import React, { useState, useEffect } from 'react';
import usePeriod from 'hooks/usePeriod';
import { useGlobalContext } from 'context/globalContext';
import CustomTable from 'components/CustomTable';
import headers from 'pages/Period/GeneralPublic/headers';
import { Box, Spinner, Input, Text } from '@chakra-ui/react';
import Header from './header';

const GeneralPublic = ({ period }) => {
  const { state: { establishment: { establishmentSelected } } } = useGlobalContext();
  const { listInvoices, isLoading, error, invoicesData } = usePeriod();

  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleReload = () => {
    listInvoices(establishmentSelected.id, period, 'list_general_public');
  };

  useEffect(() => {
    if (establishmentSelected) {
      handleReload();
    }
  }, [establishmentSelected]);

  useEffect(() => {
    if (invoicesData) {
      const filtered = invoicesData.tickets.filter(ticket => Object.values(ticket).some(value => (value ? value.toString().toLowerCase().includes(filter.toLowerCase()) : false)));
      setFilteredData(filtered);
    }
  }, [filter, invoicesData]);

  return (
    <Box>
      {isLoading ? (
        <Spinner size='lg' color='blue.800' />
      ) : invoicesData ? (
        <>
          <Header data={invoicesData} onReload={handleReload} />
          <Input
            placeholder='Buscar...'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            mb={4}
            color='blue.800'
          />
          <CustomTable data={filteredData} headers={headers} />
        </>
      ) : error ? (
        <Text color='tomato'>{error}</Text>
      ) : null}
    </Box>
  );
};

export default GeneralPublic;
